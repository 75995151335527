<template>
  <b-container class="p-3x" box>
    <b-card class="bs-br" body-class="d-flex justify-content-center p-0">
      <b-form @submit.prevent="createUser" class="mt-5 mb-5 form-add-user">
        <b-form-group
          id="input-group-1"
          label="First name *"
          label-for="input-1"
          class="mb-3"
        >
          <b-form-input
            id="input-1"
            v-model="form.name"
            type="text"
            placeholder="John"
            :class="{'is-invalid': $v.form.name.$error}"
          ></b-form-input>
          <span role="alert" class="invalid-feedback">
            <strong v-if="!$v.form.name.required">The name is required</strong>
            <strong v-if="!$v.form.name.minLength">The name must have at least {{$v.form.name.$params.minLength.min}} letters</strong>
            <strong v-if="!$v.form.name.maxLength">The name must be less than {{$v.form.name.$params.maxLength.max}} letters</strong>
          </span>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Last name *"
          label-for="input-2"
          class="mb-3"
        >
          <b-form-input
            id="input-2"
            v-model="form.last_name"
            type="text"
            placeholder="Doe"
            :class="{'is-invalid': $v.form.last_name.$error}"
          ></b-form-input>
          <span role="alert" class="invalid-feedback">
            <strong v-if="!$v.form.last_name.required">The last name is required</strong>
            <strong v-if="!$v.form.last_name.minLength">The last name must have at least {{$v.form.last_name.$params.minLength.min}} letters</strong>
            <strong v-if="!$v.form.last_name.maxLength">The last name must be less than {{$v.form.last_name.$params.maxLength.max}} letters</strong>
          </span>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="E-Mail Address *"
          label-for="input-3"
          class="mb-3"
        >
          <b-form-input
            id="input-3"
            v-model="form.email"
            type="text"
            placeholder="user@user.com"
            :class="{'is-invalid': $v.form.email.$error || errorEmail}"
          ></b-form-input>
          <span role="alert" class="invalid-feedback">
            <strong v-if="!$v.form.email.required">The e-mail address is required</strong>
            <strong v-if="!$v.form.email.email">Invalid E-mail format</strong>
            <strong v-if="!$v.form.email.maxLength">The e-mail address must be less than {{$v.form.email.$params.maxLength.max}} letters</strong>
            <strong v-if="errorEmail">This email address has already been taken</strong>
          </span>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Roles *"
          label-for="input-4"
          class="mb-3"
          v-if="checkPermission('Create User - Users')"
        >
          <b-form-checkbox-group
            id="input-4"
            v-model="form.roles"
            :options="roles"
            name="roles"
            :state="$v.form.roles.$error"
          ></b-form-checkbox-group>
          <b-form-invalid-feedback :state="!$v.form.roles.$error">You must select at least 1 role</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Agency *"
          label-for="input-5"
          class="mb-3"
          v-if="isAgency && checkPermission('Create User - Users')"
        >
          <b-form-select
            id="input-5"
            v-model="form.agency_id"
            :options="agencies"
            class="form-control"
            :class="{'is-invalid': $v.form.agency_id.$error}"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>-- Please select an agency --</b-form-select-option>
            </template>
          </b-form-select>
          <span role="alert" class="invalid-feedback">
            <strong v-if="!$v.form.agency_id.required">The agency is required</strong>
          </span>
        </b-form-group>
        <div class="d-flex justify-content-end">
          <b-button type="submit" variant="primary">Create User</b-button>
        </div>
      </b-form>
    </b-card>
  </b-container>
</template>

<script>

import { required, email, minLength, maxLength} from 'vuelidate/lib/validators';

export default {
  name: 'add_user',
  data() {
    return {
      form: {
        name: null,
        last_name: null,
        email: null,
        roles: [!this.checkPermission('Create User - Users') ? 'Content Creator' : null],
        agency_id: !this.checkPermission('Create User - Users') ? this.$session.get('userInfo').agency_id : null
      },
      isRequiredAgency: false,
      roles: [],
      agencies: [],
      check: false,
      load: false,
      errorEmail: false,
    };
  },
  validations() {
    if (this.isAgency) {
      return {
        form: {
          name: { required, minLength: minLength(2), maxLength: maxLength(60) },
          last_name: { required, minLength: minLength(2), maxLength: maxLength(60) },
          email: { required, email, maxLength: maxLength(60) },
          roles: { required },
          agency_id: { required }
        }
      }
    }
    return {
      form: {
        name: { required, minLength: minLength(2), maxLength: maxLength(60) },
        last_name: { required, minLength: minLength(2), maxLength: maxLength(60) },
        email: { required, email, maxLength: maxLength(60) },
        roles: { required }
      }
    }

  },
  mounted(){
    if(this.checkPermission('Create User - Users')) {
      this.getRoles();
    }
    this.getAgencies();
  },
  methods: {
    createUser(){
      this.$v.form.$reset();
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.load = true;
        this.$http.post('user', this.form, { headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
          if (response.status === 200) {
            this.$notify({ group: 'app', text: response.body.message, type: 'success' });
            this.$router.push({ name: 'users.index' })
          }
          this.load = false;
        }, (error) => {
          if (error.status == 500) {
            this.$notify({ group: 'app', text: 'An error occurred while creating the user. Please try again.', type: 'error' });
          }
          if (error.status == 400) {
            if (error.body.response == 'error_email') {
              this.errorEmail = true;
            }
          }
          this.load = false;
        })
      }
    },
    getRoles(){
      this.$http.get('getRolesByName', { headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
        if (response.status == 200) {
          this.roles = response.body.data;
        }
      }, (error) => {
        if (error.status == 500) {
          this.$notify({ group: 'app', text: 'An error occurred while getting the roles. Please try again.', type: 'error' });
        }
      })
    },
    getAgencies(){
      this.$http.get('getAgenciesList', { headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
        if (response.status === 200) {
          this.agencies = response.body.data;
        }
      }, (error) => {
        if (error.status == 500) {
          this.$notify({ group: 'app', text: 'An error occurred while obtaining the agencies. Please try again.', type: 'error' });
        }
      })
    }
  },
  computed: {
    isAgency(){
      return (this.form.roles).some((role) => role === 'Agency' || role === 'Content Creator');
    }
  }
};
</script>
